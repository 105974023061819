import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {applyMiddleware, createStore} from 'redux';
import promise from 'redux-promise';
import thunkMiddleware from 'redux-thunk';
import {TranslatorProvider} from 'react-translate';

import translations from './i18n';

import App from './app';
import reducers from './reducers';
import {initSentry, setSentryUnknownUser} from "./utils/sentry";

const sentry_environment = process.env.SENTRY_ENV;
if (!!sentry_environment) {
    initSentry(sentry_environment, process.env.COMMIT_HASH);
    setSentryUnknownUser();
}

const createStoreWithMiddleware = applyMiddleware(thunkMiddleware, promise)(createStore);

ReactDOM.render(
    <Provider store={createStoreWithMiddleware(reducers)}>
        <TranslatorProvider translations={translations}>
            <App/>
        </TranslatorProvider>
    </Provider>
    , document.querySelector('#container'));
