import {COOKIE_BANNER_HIDE} from "../values/action_types";
import lsvars from '../values/local_storage_vars';

import {log} from "../utils/utils";

const {COOKIE_INFO} = lsvars;
const first_show = localStorage.getItem(COOKIE_INFO.id);
const show = first_show ? first_show === COOKIE_INFO.values.true : true;

const INITIAL_STATE = {
    show
};

export default function (state = INITIAL_STATE, action) {
    let _log_ = true;

    const {type} = action;
    let newState = {...state};

    switch (type) {
        case COOKIE_BANNER_HIDE:
            localStorage.setItem(COOKIE_INFO.id, COOKIE_INFO.values.false);

            // show whatsUp chat after cookie banner disabled
            let whats_up = document.getElementById("whats-up-icon");
            if (whats_up) { 
                whats_up.style.display = "inherit";
            }

            newState = {
                ...state,
                show: false,
            };
            break;
        default:
            _log_ = false;
            break;
    }

    if (_log_) {
        log('COOKIE TERMS prev state: ', state);
        log('New cookie terms action: ', action);
        log('COOKIE TERMS new state: ', newState);
    }

    return newState;
}