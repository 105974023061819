import {
    IMAGE_APPEND_SUCCESS,
    IMAGE_FETCH_ERROR,
    IMAGE_FETCH_SUCCESS,
    IMAGE_LOADING,
    IMAGE_RESET, IMAGE_UPDATE_WS, IMAGE_WS_CLOSED, IMAGE_WS_OPEN
} from "../values/action_types";
import {log} from "../utils/utils";

const LIST_PROP = 'hits';
const INITIAL_STATE = {
    loading: false,
    [LIST_PROP]: [],
    websocket_closed: false
};

export default function (state = INITIAL_STATE, action) {
    let _log_ = true;

    const {type, payload, error} = action;
    let newState = state;
    let old_list = state[LIST_PROP];

    switch (type) {
        case IMAGE_LOADING:
            newState = {
                ...state,
                loading: true,
            };
            break;
        case IMAGE_FETCH_SUCCESS:
            newState = {
                ...state,
                ...payload,
                loading: false,
            };
            break;
        case IMAGE_APPEND_SUCCESS:
            newState = {
                ...state,
                ...payload,
                [LIST_PROP]: old_list.concat(payload[LIST_PROP]),
                loading: false,
            };
            break;
        case IMAGE_FETCH_ERROR:
            newState = {
                ...state,
                error,
                loading: false,
            };
            break;
        case IMAGE_UPDATE_WS:
            newState = {
                ...state,
                [LIST_PROP]: [payload].concat(state[LIST_PROP] || []),
            };
            break;
        case IMAGE_WS_CLOSED:
            newState = {
                ...state,
                websocket_closed: true
            };
            break;
        case IMAGE_WS_OPEN:
            newState = {
                ...state,
                websocket_closed: INITIAL_STATE.websocket_closed
            };
            break;
        case IMAGE_RESET:
            newState = {...INITIAL_STATE};
            break;
        default:
            _log_ = false;
            break;
    }

    if (_log_) {
        log('IMAGES prev state: ', state);
        log('New images action: ', action);
        log('IMAGES new state: ', newState);
    }

    return newState;
}
