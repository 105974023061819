import * as Sentry from '@sentry/browser';

export const initSentry = (environment, release) => {
    Sentry.init({
        dsn: "https://b24162095a97444eb6ecdc3531c041f3@sentry.io/1835068",
        environment,
        release,
        beforesend: (event, hint) => {
            const error = hint.originalException;
            if (
                error &&
                error.message &&
                // not authorized or network error
                (error.message.includes("401") || error.message.includes("ms exceeded"))
            ) {
                event = null;
            }
            return event;
        }
    });
};

export const setSentryUser = (id, email, username) => {
    Sentry.configureScope( () => {
        Sentry.setUser({
            id,
            email,
            username
        });
    });
};

export const setSentryUnknownUser = () => {
    Sentry.configureScope( () => {
        Sentry.setUser({
            id: 'unknown',
            email: 'unknown',
            username: 'unknown',
            ip_address: '-'
        });
    });
};