import _ from 'lodash';

import {
    DASHBOARD_FETCH_ERROR,
    DASHBOARD_FETCH_SUCCESS,
    DASHBOARD_LOADING,
    DASHBOARD_RESET,
    DASHBOARD_UPDATE_WS, DASHBOARD_WS_CLOSED, DASHBOARD_WS_OPEN,
} from "../values/action_types";
import {log} from "../utils/utils";
import {getPlantIdByName, getSensorIdByName} from "../utils/sensor_utils";

const INITIAL_STATE = {
    list: null,
    error: null,
    loading: false,
    websocket_closed: false
};

export default function (state = INITIAL_STATE, action) {
    let _log_ = true;

    const {type, payload, error} = action;
    let newState = {};

    switch (type) {
        case DASHBOARD_LOADING:
            newState = {
                ...state,
                loading: true,
            };
            break;
        case DASHBOARD_FETCH_SUCCESS:
            newState = {
                ...state,
                list: _.mapKeys(payload, 'id'),
                loading: false,
                error: INITIAL_STATE.error
            };
            break;
        case DASHBOARD_FETCH_ERROR:
            newState = {
                ...state,
                loading: false,
                error,
            };
            break;
        case DASHBOARD_UPDATE_WS:
            const plant_name = _.keys(payload)[0];
            const sensor_name = _.keys((payload[plant_name])[0])[0];
            const plant_id = getPlantIdByName(plant_name, state.list);
            const sensor_id = getSensorIdByName(sensor_name, state.list[plant_id].sensors);

            const sensors = [...state.list[plant_id].sensors];
            const sensor_index = sensors.findIndex(s => s.id === sensor_id);
            sensors[sensor_index] = {
                ...sensors[sensor_index],
                ...((payload[plant_name])[0])[sensor_name],
            };

            newState = {
                ...state,
                list: {
                    ...state.list,
                    [plant_id]: {
                        ...state.list[plant_id],
                        sensors,
                    },
                },
            };
            break;
        case DASHBOARD_WS_OPEN:
            newState = {
                ...state,
                websocket_closed: INITIAL_STATE.websocket_closed
            };
            break;
        case DASHBOARD_WS_CLOSED:
            newState = {
                ...state,
                websocket_closed: true
            };
            break;
        case DASHBOARD_RESET:
        default:
            newState = state;
            _log_ = false;
            break;
    }

    if (_log_) {
        log('DASHBOARD prev state: ', state);
        log('New dashboard action: ', action);
        log('DASHBOARD new state: ', newState);
    }

    return newState;
}
