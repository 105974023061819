import axios from "axios";

import {buildActionObject, getApiPath, handleError} from "../utils/actions_utils";
import {
    AUTH_CHECK_COOKIE_ERROR,
    AUTH_CHECK_COOKIE_SUCCESS,
    LOGIN_LOADING,
    NAVIGATION_RESET_REDIRECT
} from "../values/action_types";
import {USERS_APP as APP_NAME} from "../values/api_apps";
import {reqOptions} from "../utils/auth";

export const refreshAuth = () => dispatch => {
    dispatch(buildActionObject(LOGIN_LOADING));

    const url = getApiPath(APP_NAME, '/refresh-token/');

    return axios.post(url, null, reqOptions)
        .then(res => dispatch(buildActionObject(AUTH_CHECK_COOKIE_SUCCESS, res.data)))
        .catch(error => handleError(error, dispatch, AUTH_CHECK_COOKIE_ERROR));
};

export const resetNavigationRedirect = () => buildActionObject(NAVIGATION_RESET_REDIRECT);