import {
    CURRENT_NOTIFICATION_FETCH_ERROR,
    CURRENT_NOTIFICATION_FETCH_SUCCESS,
    CURRENT_NOTIFICATION_RESET,
    CURRENT_NOTIFICATION_STATUS_FETCH_ERROR,
    CURRENT_NOTIFICATION_STATUS_FETCH_SUCCESS,
    CURRENT_NOTIFICATION_STATUS_RESET,
    NEW_NOTIFICATION_RECEIVED,
    NOTIFICATIONS_APPEND_ERROR,
    NOTIFICATIONS_APPEND_SUCCESS,
    NOTIFICATIONS_CLEAR,
    NOTIFICATIONS_FETCH_ERROR,
    NOTIFICATIONS_FETCH_SUCCESS,
    NOTIFICATIONS_LOADING,
    NOTIFICATIONS_READ_ERROR,
    NOTIFICATIONS_READ_SUCCESS,
    NOTIFICATIONS_SET_FETCH_PARAMS,
} from "../values/action_types";
import {log} from "../utils/utils";

const LIST_PROP_NAME = 'results';
const INITIAL_STATE = {
    read: {},
    fetch_params: {},
    current: null,
    current_status: null,
    loading: false,
    [LIST_PROP_NAME]: [],
};

export default function (state = INITIAL_STATE, action) {
    let _log_ = true;

    const {type, payload, error} = action;
    let newState = state;
    let old_list = state[LIST_PROP_NAME] || [];
    let new_list = old_list;
    const append_list = payload ? (payload[LIST_PROP_NAME] || []) : [];

    switch (type) {
        case NOTIFICATIONS_LOADING:
            newState = {
                ...state,
                loading: true,
            };
            break;
        case NOTIFICATIONS_FETCH_SUCCESS:
            newState = {
                ...state,
                ...payload,
                loading: false,
            };
            break;
        case NOTIFICATIONS_APPEND_SUCCESS:
            new_list = old_list.concat(append_list);
            newState = {
                ...state,
                ...payload,
                [LIST_PROP_NAME]: new_list,
                loading: false,
            };
            break;
        case NOTIFICATIONS_FETCH_ERROR:
        case NOTIFICATIONS_APPEND_ERROR:
            newState = {
                ...state,
                error,
                loading: false,
            };
            break;
        case NOTIFICATIONS_READ_SUCCESS:
            const list = state[LIST_PROP_NAME];
            const mod_n_index = list.findIndex(n => n.id === payload.id);
            list[mod_n_index] = payload;

            newState = {
                ...state,
                [LIST_PROP_NAME]: list,
                read: payload,
                loading: false,
            };
            break;
        case NOTIFICATIONS_READ_ERROR:
            newState = {
                ...state,
                read: error,
                loading: false,
            };
            break;
        case NEW_NOTIFICATION_RECEIVED:
            new_list = [payload.notification].concat(old_list);

            newState = {
                ...state,
                [LIST_PROP_NAME]: new_list,
            };
            break;
        case NOTIFICATIONS_SET_FETCH_PARAMS:
            newState = {
                ...state,
                fetch_params: payload.params,
            };
            break;
        case CURRENT_NOTIFICATION_FETCH_SUCCESS:
            newState = {
                ...state,
                current: payload,
                loading: false,
            };
            break;
        case CURRENT_NOTIFICATION_FETCH_ERROR:
            newState = {
                ...state,
                current: {error},
                loading: false,
            };
            break;
        case CURRENT_NOTIFICATION_RESET:
            newState = {
                ...state,
                current: null,
            };
            break;
        case CURRENT_NOTIFICATION_STATUS_FETCH_SUCCESS:
            newState = {
                ...state,
                current_status: payload,
                loading: false,
            };
            break;
        case CURRENT_NOTIFICATION_STATUS_FETCH_ERROR:
            newState = {
                ...state,
                current_status: {error},
                loading: false,
            };
            break;
        case CURRENT_NOTIFICATION_STATUS_RESET:
            newState = {
                ...state,
                current_status: null,
            };
            break;
        case NOTIFICATIONS_CLEAR:
            newState = INITIAL_STATE;
            break;
        default:
            _log_ = false;
            break;
    }

    if (_log_) {
        log('NOTIFICATIONS prev state: ', state);
        log('New notifications action: ', action);
        log('NOTIFICATIONS new state: ', newState);
    }

    return newState;
}