import {combineReducers} from 'redux';

import NavigationReducer from './navigation';
import UserReducer from './user';
import DashboardReducer from './dashboard';
import PlantDetailReducer from './plant_detail';
import SensorDetailReducer from './sensor_detail';
import AlarmsReducer from './alarms';
import ImagesReducer from './images';
import NotificationsReducer from './notifications';
import CommandReducer from './commands';
import SettingsReducer from './settings';
import CookieBannerReducer from './cookie_terms';
import SensorConfigurationReducer from './sensor_config';

const rootReducer = combineReducers({
    navigation: NavigationReducer,
    user: UserReducer,
    dashboard: DashboardReducer,
    currentPlant: PlantDetailReducer,
    currentSensor: SensorDetailReducer,
    alarms: AlarmsReducer,
    images: ImagesReducer,
    notifications: NotificationsReducer,
    command: CommandReducer,
    settings: SettingsReducer,
    cookieBanner: CookieBannerReducer,
    sensorConfiguration: SensorConfigurationReducer,
});

export default rootReducer;
