import {
    AUTH_CHECK_COOKIE_ERROR,
    AUTH_CHECK_COOKIE_SUCCESS,
    AUTH_SET_NEXT,
    LOGIN_ERROR,
    LOGIN_LOADING,
    LOGIN_SUCCESS,
    LOGOUT
} from '../values/action_types';
import urls from "../values/urls";

import {getLocalStorageCurrentUser, removeLocalStorageCurrentUser, setLocalStorageCurrentUser} from "../utils/auth";
import {log} from "../utils/utils";
import {setSentryUnknownUser, setSentryUser} from "../utils/sentry";

const INITIAL_STATE = {
    isAuthenticated: false,
    loading: false,
    next: urls.DASHBOARD_BASE,
    user: getLocalStorageCurrentUser(),
};

export default function (state = INITIAL_STATE, action) {
    let _log_ = true;

    const {type, payload, error} = action;
    let newState = state;

    switch (type) {
        case LOGIN_LOADING:
            newState = {
                ...state,
                loading: true,
            };
            break;
        case LOGIN_SUCCESS:
            setSentryUser(payload.pk.toString(), payload.email, `${payload.first_name} ${payload.last_name}`);

            newState = {
                ...state,
                user: payload,
                isAuthenticated: true,
                loading: false,
                error: null,
            };

            // set session user
            setLocalStorageCurrentUser(payload);
            break;
        case LOGIN_ERROR:
            // remove session user
            removeLocalStorageCurrentUser();

            setSentryUnknownUser();

            newState = {
                ...state,
                error,
                loading: false,
            };
            break;
        case LOGOUT:
            // remove session user
            removeLocalStorageCurrentUser();

            setSentryUnknownUser();

            newState = {
                ...INITIAL_STATE,
                user: null
            };
            break;
        case AUTH_SET_NEXT:
            // add link to redirect after login
            const new_next = payload.next !== urls.LOGIN ? payload.next : state.next;

            newState = {
                ...state,
                next: new_next,
            };
            break;
        case AUTH_CHECK_COOKIE_SUCCESS:
            const {token, user} = payload;
            const newUser = {...user, token};

            setSentryUser(newUser.pk.toString(), newUser.email, `${newUser.first_name} ${newUser.last_name}`);

            newState = {
                ...state,
                user: newUser,
                error: null,
                isAuthenticated: true,
                loading: false,
            };

            // set session user
            setLocalStorageCurrentUser(newUser);
            break;
        case AUTH_CHECK_COOKIE_ERROR:
            removeLocalStorageCurrentUser();

            setSentryUnknownUser();

            newState = {
                user: null,
                error,
                isAuthenticated: false,
                loading: false,
            };
            break;
        default:
            _log_ = false;
            break;
    }

    if (_log_) {
        log('AUTH prev state: ', state);
        log('New auth action: ', action);
        log('AUTH new state: ', newState);
    }

    return newState;
}