import {COMMAND_ERROR, COMMAND_RESET, COMMAND_SUCCESS, COMMAND_WAITING} from "../values/action_types";
import {log} from "../utils/utils";

const INITIAL_STATE = {
    waiting: false,
    switch: false,
};

export default function (state = INITIAL_STATE, action) {
    let _log_ = true;

    const {type, payload, error} = action;
    let newState = state;

    switch (type) {
        case COMMAND_WAITING:
            newState = {
                ...state,
                waiting: true,
                switch: false,
            };
            break;
        case COMMAND_SUCCESS:
            newState = {
                waiting: false,
                response: payload,
                switch: true,
                error: null,
            };
            break;
        case COMMAND_ERROR:
            newState = {
                waiting: false,
                switch: false,
                error,
            };
            break;
        case COMMAND_RESET:
            newState = {...INITIAL_STATE};
            break;
        default:
            _log_ = false;
            break;
    }

    if (_log_) {
        log('COMMANDS prev state: ', state);
        log('New commands action: ', action);
        log('COMMANDS new state: ', newState);
    }

    return newState;
}