import {
    PLANT_FIRST_SENSOR_ALARM,
    PLANT_FETCH_ERROR,
    PLANT_FETCH_SUCCESS,
    PLANT_LOADING,
    PLANT_RESET,
    PLANT_UPDATE_WS, PLANT_WS_CLOSED, PLANT_WS_OPEN, PLANT_SECOND_SENSOR_LIGHT,
} from "../values/action_types";
import {log} from "../utils/utils";

const INITIAL_STATE = {
    loading: false,
    object: null,
    error: null,
    websocket_closed: false,
    plant_mode: {}
};

export default function (state = INITIAL_STATE, action) {
    let _log_ = true;

    const {type, payload, error} = action;
    let newState = {};

    switch (type) {
        case PLANT_LOADING:
            newState = {
                ...state,
                loading: true,
            };
            break;
        case PLANT_FETCH_SUCCESS:
            newState = {
                ...state,
                loading: false,
                object: payload[0] || payload, // TODO fix router api, because for router is only payload not payload[0],
                error: null,
            };
            break;
        case PLANT_FETCH_ERROR:
            newState = {
                ...state,
                loading: false,
                error,
            };
            break;
        case PLANT_RESET:
            newState = {
                ...INITIAL_STATE,
            };
            break;
        case PLANT_UPDATE_WS:
            const ws_plant_key = Object.keys(payload)[0];
            const sensor = payload[ws_plant_key][0];
            const ws_sensor_key = Object.keys(sensor)[0];

            let old_sensors = [...(state.object || {sensors: []}).sensors];
            const sensor_pos = old_sensors.findIndex(s => s.name.toString() === ws_sensor_key.toString());
            if (sensor_pos > -1) {
                const old_sensor = {...old_sensors[sensor_pos]};
                old_sensors[sensor_pos] = {
                    ...old_sensor,
                    ...sensor[ws_sensor_key],
                };
            }

            newState = {
                ...state,
                object: {
                    ...state.object,
                    sensors: [...old_sensors],
                }
            };
            break;
        case PLANT_WS_OPEN:
            newState = {
                ...state,
                websocket_closed: INITIAL_STATE.websocket_closed
            };
            break;
        case PLANT_WS_CLOSED:
            newState = {
                ...state,
                websocket_closed: true
            };
            break;
        case PLANT_FIRST_SENSOR_ALARM:
            newState = {
                ...INITIAL_STATE,
                object: {
                    ...state.object,
                    sensors: [
                        {
                            ...state.object.sensors[0],
                            alarm_status: {
                                insert_date: Date.now()
                            },
                            last_status: {}
                        },
                        ...state.object.sensors.slice(1, state.object.sensors.length)
                    ]
                }
            };
            break;
        case PLANT_SECOND_SENSOR_LIGHT:

            newState = {
                ...INITIAL_STATE,
                object: {
                    ...state.object,
                    sensors: [
                        state.object.sensors[0],
                        {
                            ...state.object.sensors[1],
                            last_status: {
                                web_sem: !!payload
                            }
                        },
                        ...state.object.sensors.slice(2, state.object.sensors.length)
                    ]
                }
            };
            break;
        default:
            _log_ = false;
            newState = {...state};
            break;
    }

    if (_log_) {
        log('CURRENT PLANT prev state: ', state);
        log('New current plant action: ', action);
        log('CURRENT PLANT new state: ', newState);
    }

    return newState;
}
