import {
    NAVIGATION_SET_REDIRECT,
    NAVIGATION_RESET_REDIRECT, NAVIGATION_SET_LOCATION,
} from "../values/action_types";

const INITIAL_STATE = {
    redirect: null,
    location: {},
};

export default function (state = INITIAL_STATE, action) {
    const {type, payload} = action;
    let newState = state;

    switch (type) {
        case NAVIGATION_SET_REDIRECT:
            newState = {
                ...state,
                redirect: payload,
            };
            break;
        case NAVIGATION_RESET_REDIRECT:
            newState = {
                ...state,
                redirect: null,
            };
            break;
        case NAVIGATION_SET_LOCATION:
            newState = {
                ...state,
                location: payload,
            };
            break;
        default:
            break;
    }

    return newState;
}