import {
    ALL_PLANTS_FETCH_ERROR,
    ALL_PLANTS_FETCH_SUCCESS,
    ALL_SENSORS_FETCH_ERROR,
    ALL_SENSORS_FETCH_SUCCESS,
    ALL_PLANTS_FETCH_LOADING,
    DAYS_FETCH_ERROR,
    DAYS_FETCH_SUCCESS,
    EVENTS_FETCH_ERROR,
    EVENTS_FETCH_SUCCESS,
    RULE_CREATE_ERROR,
    RULE_CREATE_SUCCESS,
    RULE_DELETE_SUCCESS,
    RULES_FETCH_ERROR,
    RULES_FETCH_SUCCESS,
    RULES_UPDATE_ERROR,
    RULES_UPDATE_SUCCESS,
    TIME_FILTERS_FETCH_ERROR,
    TIME_FILTERS_FETCH_SUCCESS,
} from "../values/action_types";
import {log} from "../utils/utils";

const INITIAL_STATE = {
    notifications: {
        /* objects { ...some_props, results: [list] } */
        days: null,
        time_filters: null,
        rules: null,
    },
    sensors: null,
    plants: [],
    events: null,
    loading: false,
};

const buildNotificationsState = (state, data) => {
    return {
        ...state,
        notifications: {
            ...state.notifications,
            ...data,
        }
    };
};

export default function (state = INITIAL_STATE, action) {
    const {type, payload, error} = action;
    let newState = state;
    let _log_ = true;

    switch (type) {
        case DAYS_FETCH_SUCCESS:
            newState = buildNotificationsState(state, {days: payload});
            break;
        case DAYS_FETCH_ERROR:
            newState = buildNotificationsState(state, {days: error});
            break;
        case TIME_FILTERS_FETCH_SUCCESS:
            newState = buildNotificationsState(state, {time_filters: payload});
            break;
        case TIME_FILTERS_FETCH_ERROR:
            newState = buildNotificationsState(state, {time_filters: error});
            break;
        case RULES_FETCH_SUCCESS:
            newState = buildNotificationsState(state, {rules: payload});
            break;
        case RULES_FETCH_ERROR:
        case RULES_UPDATE_ERROR:
        case RULE_CREATE_ERROR:
            newState = buildNotificationsState(state, {rules: error});
            break;
        // case RULE_CHANGED_SUCCESS:
        //     const {rules} = state.notifications;
        //     let {results} = rules;
        //     const rule_index = results.findIndex(r => r.id === payload.id);
        //     results[rule_index] = {
        //         ...results[rule_index],
        //         ...payload,
        //     };
        //
        //     return buildNotificationsState({
        //         ...state.notifications,
        //         rules: {
        //             ...rules,
        //             results,
        //         },
        //     });
        case RULES_UPDATE_SUCCESS:
            const {rules} = state.notifications;
            let {results} = rules;

            // update existing rules
            for (let i = 0; i < payload.length; i++) {
                const index = results.findIndex(r => r.id === payload[i].data.id);
                results[index] = payload[i].data;
            }

            newState = buildNotificationsState(state, {
                rules: {
                    ...rules,
                    results,
                }
            });
            break;
        case RULE_DELETE_SUCCESS:
            const ruleList = state.notifications.rules;
            let list = ruleList.results;

            // delete rule
            const index = list.findIndex(r => `${r.id}` === `${payload.id_rule}`);
            if (index >= 0) list.splice(index, 1);

            newState = buildNotificationsState(state, {
                rules: {
                    ...ruleList,
                    results: list,
                }
            });
            break;
        case RULE_CREATE_SUCCESS:
            let rule_obj = state.notifications.rules;
            const new_id = payload.id;
            let find_index = state.notifications.rules.results.findIndex(r => r.id === new_id);

            // if rule not found, update
            if (find_index < 0) {
                rule_obj.results.push(payload);
                newState = buildNotificationsState(state, {rules: rule_obj});
            }
            break;
        case ALL_SENSORS_FETCH_SUCCESS:
            newState = {
                ...state,
                sensors: payload,
            };
            break;
        case ALL_SENSORS_FETCH_ERROR:
            newState = {
                ...state,
                sensors: error,
            };
            break;
        case ALL_PLANTS_FETCH_SUCCESS:
            newState = {
                ...state,
                plants: payload,
                loading: false,
            };
            break;
        case ALL_PLANTS_FETCH_ERROR:
            newState = {
                ...state,
                plants: error,
                loading: false,
            };
            break;
        case EVENTS_FETCH_SUCCESS:
            newState = {
                ...state,
                events: payload,
            };
            break;
        case EVENTS_FETCH_ERROR:
            newState = {
                ...state,
                events: error,
            };
            break;
        case ALL_PLANTS_FETCH_LOADING:
            newState = {
                ...state,
                loading: true,
            };
            break;
        default:
            _log_ = false;
            break;
    }

    if (_log_) {
        log('SETTINGS prev state: ', state);
        log('New settings action: ', action);
        log('SETTINGS new state: ', newState);
    }

    return newState;
}