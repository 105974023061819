import {
    SENSOR_CONFIG_FETCH_ERROR,
    SENSOR_CONFIG_FETCH_SUCCESS,
    SENSOR_CONFIG_LOADING,
    SENSOR_CONFIG_SAVE_SUCCESS
} from "../values/action_types";
import {log} from "../utils/utils";

export const StateProps = Object.freeze({
    LOADING: 'loading',
    CONFIG: 'config'
});

const calculate_new_loading = (state, prop_ignore) => {
    if (state[StateProps.LOADING]) {
        const {current_values, default_values} = state.config;

        let loading;
        switch (prop_ignore) {
            case StateProps.CONFIG:
                loading = !current_values || !default_values;
                break;
            default:
                loading = state[StateProps.LOADING];
        }

        return loading;
    }

    return false;
};


export const INITIAL_STATE = {
    [StateProps.CONFIG]: {'current_values': null, 'default_values': null},
    [StateProps.LOADING]: false,
};

export default function (state = INITIAL_STATE, action) {
    let _log_ = true;

    const {type, payload, error} = action;
    let newState = {};

    switch (type) {
        case SENSOR_CONFIG_LOADING:
            newState = {
                ...state,
                [StateProps.LOADING]: true,
            };
            break;
        case SENSOR_CONFIG_FETCH_SUCCESS:
            const {current_values, default_values} = payload;
            newState = {
                ...state,
                [StateProps.CONFIG]: {
                    'current_values': {
                        ...state.config.current_values,
                        ...current_values
                    },
                    'default_values': {
                        ...state.config.default_values,
                        ...default_values
                    }
                },
                [StateProps.LOADING]: calculate_new_loading(newState, StateProps.CONFIG),
            };
            break;
        case SENSOR_CONFIG_FETCH_ERROR:
            newState = {
                ...state,
                [StateProps.CONFIG]: {error},
                [StateProps.LOADING]: calculate_new_loading(newState, StateProps.CONFIG),
            };
            break;
        case SENSOR_CONFIG_SAVE_SUCCESS:
            newState = {
                ...state,
                [StateProps.CONFIG]: 'uploaded',
                [StateProps.LOADING]: calculate_new_loading(newState, StateProps.CONFIG)
            };
            break;
        default:
            newState = state;
            _log_ = false;
            break;
    }

    if (_log_) {
        log('CURRENT SENSOR prev state: ', state);
        log('New current sensor action: ', action);
        log('CURRENT SENSOR new state: ', newState);
    }
    return newState;
}
