import _ from 'lodash';

import {
    ALARM_SENSOR_FETCH_ERROR,
    ALARM_SENSOR_FETCH_SUCCESS,
    ALARM_SENSOR_UPDATE_WS,
    IMAGE_SENSOR_FETCH_ERROR,
    IMAGE_SENSOR_FETCH_SUCCESS, IMAGE_SENSOR_UPDATE_WS,
    SENSOR_FETCH_ERROR,
    SENSOR_FETCH_SUCCESS,
    SENSOR_LOADING,
    SENSOR_UPDATE_WS, SENSOR_WS_CLOSED, SENSOR_WS_OPEN
} from "../values/action_types";
import {log} from "../utils/utils";

export const StateProps = Object.freeze({
    STATUS: 'status',
    ALARMS: 'alarms',
    IMAGES: 'images',
    LOADING: 'loading',
    HITS: 'hits',
});

const calculate_new_loading = (state, prop_ignore) => {
    if (state[StateProps.LOADING]) {
        const {status, alarms, images} = state;

        let loading;
        switch (prop_ignore) {
            case StateProps.STATUS:
                loading = !alarms || !images;
                break;
            case StateProps.ALARMS:
                loading = !status || !images;
                break;
            case StateProps.IMAGES:
                loading = !status || !alarms;
                break;
            default:
                loading = state[StateProps.LOADING];
        }

        return loading;
    }

    return false;
};

const create_state_by_ws_update = (state, new_data, prop) => {
    const hits = state[prop].error
        ? [new_data]
        : [new_data, ...(state[prop])[StateProps.HITS]];
    const new_state = state[prop].error
        ? {hits}
        : {...state[prop], [StateProps.HITS]: hits};

    return {
        ...state,
        [prop]: new_state,
    };
};

export const INITIAL_STATE = {
    [StateProps.STATUS]: null,
    [StateProps.ALARMS]: null,
    [StateProps.IMAGES]: null,
    [StateProps.LOADING]: false,
    websocket_closed: false,
};

export default function (state = INITIAL_STATE, action) {
    let _log_ = true;

    const {type, payload, error} = action;
    let newState = {};

    switch (type) {
        case SENSOR_LOADING:
            newState = {
                ...state,
                [StateProps.LOADING]: true,
            };
            break;
        case SENSOR_FETCH_SUCCESS:
            const {sensors, ...plant} = payload[0];
            newState = {
                ...state,
                [StateProps.STATUS]: {
                    plant,
                    sensor: sensors[0],
                },
                [StateProps.LOADING]: calculate_new_loading(state, StateProps.STATUS),
            };
            break;
        case SENSOR_UPDATE_WS:
            const obj = _.values((_.values(payload)[0])[0])[0];
            // console.log('sensor update ws');
            newState = {
                ...state,
                [StateProps.STATUS]: {
                    ...state.status,
                    sensor: {
                        ...state.status.sensor,
                        ...obj,
                    },
                },
            };
            break;
        case SENSOR_FETCH_ERROR:
            newState = {
                ...state,
                [StateProps.STATUS]: {error},
                [StateProps.LOADING]: calculate_new_loading(state, StateProps.STATUS),
            };
            break;
        case ALARM_SENSOR_FETCH_SUCCESS:
            newState = {
                ...state,
                [StateProps.ALARMS]: payload,
                [StateProps.LOADING]: calculate_new_loading(state, StateProps.ALARMS),
            };
            break;
        case ALARM_SENSOR_FETCH_ERROR:
            newState = {
                ...state,
                [StateProps.ALARMS]: {error},
                [StateProps.LOADING]: calculate_new_loading(state, StateProps.ALARMS),
            };
            break;
        case ALARM_SENSOR_UPDATE_WS:
            newState = create_state_by_ws_update(state, payload, StateProps.ALARMS);
            break;
        case IMAGE_SENSOR_FETCH_SUCCESS:
            newState = {
                ...state,
                [StateProps.IMAGES]: payload,
                [StateProps.LOADING]: calculate_new_loading(state, StateProps.IMAGES),
            };
            break;
        case IMAGE_SENSOR_FETCH_ERROR:
            newState = {
                ...state,
                [StateProps.IMAGES]: {error},
                [StateProps.LOADING]: calculate_new_loading(state, StateProps.IMAGES),
            };
            break;
        case IMAGE_SENSOR_UPDATE_WS:
            newState = create_state_by_ws_update(state, payload, StateProps.IMAGES);
            break;
        case SENSOR_WS_OPEN:
            newState = {
                ...state,
                websocket_closed: INITIAL_STATE.websocket_closed
            };
            break;
        case SENSOR_WS_CLOSED:
            newState = {
                ...state,
                websocket_closed: true
            };
            break;
        default:
            newState = state;
            _log_ = false;
            break;
    }

    if (_log_) {
        log('CURRENT SENSOR prev state: ', state);
        log('New current sensor action: ', action);
        log('CURRENT SENSOR new state: ', newState);
    }

    return newState;
}
