import {
    ALARM_APPEND_SUCCESS,
    ALARM_FETCH_ERROR,
    ALARM_FETCH_SUCCESS,
    ALARM_LOADING,
    ALARM_RESET, ALARM_RESET_NEXT_PAGE,
    ALARM_UPDATE_WS, ALARM_WS_CLOSED, ALARM_WS_OPEN
} from "../values/action_types";
import {log} from "../utils/utils";

const LIST_PROP = 'hits';
const TOTAL_PAGES_PROP = 'total_pages';

const INITIAL_STATE = {
    loading: false,
    error: null,
    [LIST_PROP]: [],
    [TOTAL_PAGES_PROP]: 0,
    nextPage: 0,
    websocket_closed: false
};

export default function (state = INITIAL_STATE, action) {
    let _log_ = true;

    const {type, payload, error} = action;
    let newState = state;
    const {nextPage} = state;

    switch (type) {
        case ALARM_LOADING:
            newState = {
                ...state,
                loading: true,
            };
            break;
        case ALARM_FETCH_SUCCESS:
            newState = {
                ...state,
                ...payload,
                loading: false,
                error: null,
                nextPage: nextPage + 1,
            };
            break;
        case ALARM_APPEND_SUCCESS:
            newState = {
                ...state,
                ...payload,
                [LIST_PROP]: state[LIST_PROP].concat(payload.hits || []),
                loading: false,
                error: null,
                nextPage: nextPage + 1,
            };
            break;
        case ALARM_FETCH_ERROR:
            newState = {
                ...state,
                loading: false,
                error,
            };
            break;
        case ALARM_UPDATE_WS:
            newState = {
                ...state,
                [LIST_PROP]: [payload].concat(state[LIST_PROP] || []),
            };
            break;
        case ALARM_WS_CLOSED:
            newState = {
                ...state,
                websocket_closed: true
            };
            break;
        case ALARM_WS_OPEN:
            newState = {
                ...state,
                websocket_closed: INITIAL_STATE.websocket_closed
            };
            break;
        case ALARM_RESET:
            newState = {...INITIAL_STATE};
            break;
        case ALARM_RESET_NEXT_PAGE:
            newState = {
                ...state,
                nextPage: INITIAL_STATE.nextPage
            };
            break;
        default:
            _log_ = false;
            break;
    }

    if (_log_) {
        log('ALARMS prev state: ', state);
        log('New alarms action: ', action);
        log('ALARMS new state: ', newState);
    }

    return newState;
}
